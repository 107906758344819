import React from 'react';
import { Container, Row, Col } from 'react-grid-system';
import { useIntl, FormattedMessage } from 'gatsby-plugin-intl';

import Layout from '../../components/Layouts/layout';
import SEO from '../../components/seo';
import Tile from '../../components/Tile/Tile';

import Blueprints from '../../svgs/blueprints.svg';
import Builder from '../../svgs/builderAvatar.svg';
import HandsHome from '../../svgs/handsholdinghome-circle.svg';
import DesktopDoc from '../../svgs/desktopDocument.svg';
import Question from '../../svgs/questionBubbles.svg';
import Webpage from '../../svgs/webpage.svg';

const BuilderDashboardPage = () => {
	const intl = useIntl();
	return (
		<Layout
			view='builder'
			subheader={<FormattedMessage id='nav.builderDashB' />}
			header={
				<span className='bold'>
					<FormattedMessage id='nav.dashboard' />
				</span>
			}
		>
			<SEO lang={intl.locale} title={intl.formatMessage({ id: 'buyerNav.workingWith' })} />
			<Container>
				<Row>
					<Col>
						<p style={{ fontStyle: `italic` }}>
							<FormattedMessage id='nav.clickThrough' />
						</p>
					</Col>
				</Row>

				<Row className='pageRow'>
					<Col xl={4} md={6} sm={12}>
						<Tile
							icon={<Builder />}
							link={{
								text: <FormattedMessage id='builderNav.become' />,
								path: '/builder-vendor/become-a-licensed-builder/do-i-need-a-licence/'
							}}
						/>
					</Col>

					<Col xl={4} md={6} sm={12}>
						<Tile
							icon={<Webpage />}
							link={{
								external: true,
								text: <FormattedMessage id='nav.portalLink' />,
								path: 'https://builderportal.hcraontario.ca'
							}}
						/>
					</Col>

					<Col xl={4} md={6} sm={12}>
						<Tile
							icon={<HandsHome />}
							link={{
								text: <FormattedMessage id='builderNav.complaints' />,
								path: '/builder-vendor/complaints-compliance-enforcement/complaints/'
							}}
						/>
					</Col>

					<Col xl={4} md={6} sm={12}>
						<Tile
							icon={<Blueprints />}
							link={{
								text: <FormattedMessage id='becomeBuilder2.headerA' />,
								path: '/builder-vendor/competency-requirements/'
							}}
						/>
					</Col>

					<Col xl={4} md={6} sm={12}>
						<Tile
							icon={<DesktopDoc />}
							link={{
								text: <FormattedMessage id='nav.directives' />,
								path: '/licensing-compliance/directives-advisories/'
							}}
						/>
					</Col>

					<Col xl={4} md={6} sm={12}>
						<Tile
							icon={<Question />}
							link={{
								text: 'FAQs',
								path: '/builder-vendor/faq/'
							}}
						/>
					</Col>
				</Row>
			</Container>
		</Layout>
	);
};

export default BuilderDashboardPage;
